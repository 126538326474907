'use client'

import Header from '../../components/pages/mainpage/header';
import Stats from '../../components/pages/mainpage/stats';
import PlayerManagment from '../../components/pages/mainpage/playerManagment';
import SplashScreen from '../../components/pages/mainpage/splashScreen';
import SellingPoints from '../../components/pages/mainpage/sellingPoints';
import LoadingScreen from '../../components/utility/LoadingScreen';
import LoadParticles from '../../components/utility/Particles';
import React, { useState, useEffect } from 'react';
import Title from '../../components/pages/mainpage/title';
import GridOverlay from '../../components/pages/mainpage/GridOverlay';
import Footer from '../../components/pages/mainpage/footer';

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    isLoading ? <LoadingScreen /> :
      <div>
        <Header />
        <LoadParticles />

        <main className="flex min-h-screen flex-col items-center justify-between p-24">
          <section className="text-center grid-background">
            <GridOverlay spacing={40} stroke="#ffffff" opacity={0.1} />
            <Title />
            <SplashScreen />
            <Stats />
            <SellingPoints />
            <PlayerManagment />
          </section>

          <Footer />


        </main>
      </div>
  );
}
