// components/GridOverlay.js
import React from 'react';

const GridOverlay = ({ spacing = 40, stroke = '#ffffff', opacity = 0.1 }) => {
  return (
    <svg
      width="100%"
      height="100%"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        pointerEvents: 'none',
        opacity: opacity,
      }}
    >
      <defs>
        <pattern
          id="smallGrid"
          width={spacing}
          height={spacing}
          patternUnits="userSpaceOnUse"
        >
          <path
            d={`M ${spacing} 0 L 0 0 0 ${spacing}`}
            fill="none"
            stroke={stroke}
            strokeWidth="0.5"
          />
        </pattern>
        <pattern
          id="grid"
          width={spacing * 10}
          height={spacing * 10}
          patternUnits="userSpaceOnUse"
        >
          <rect
            width={spacing * 10}
            height={spacing * 10}
            fill="url(#smallGrid)"
          />
          <path
            d={`M ${spacing * 10} 0 L 0 0 0 ${spacing * 10}`}
            fill="none"
            stroke={stroke}
            strokeWidth="1"
          />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#grid)" />
    </svg>
  );
};

export default GridOverlay;
