function SellingPoints(){
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px', marginTop: '15%' }} className="z-10 max-w-5xl w-full items-center justify-between font-mono text-sm lg:flex" >
        <div className="bg-black text-white py-12">
          <h2 className="text-4xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">Features</h2>
          <div className="max-w-7xl mx-auto mt-8 grid grid-cols-1 lg:grid-cols-3 gap-4 px-4">

            <div className="feature flex flex-col rounded-lg shadow-lg bg-gray-800 w-full md:w-auto">
              <div className="p-6 flex-1">
                <div className="flex items-center mb-4">
                  <img src="https://cdn.economyplus.solutions/coordinator.png" alt="Feature 1 Icon" className="w-15 h-12" />
                  <h3 className="text-2xl font-bold ml-4">Realms Made easy</h3>
                </div>
                <p>Say goodbye to having to open Minecraft to manage your realm, with Fairplay you and your staff team can manage your realm right from your own discord server.</p>
              </div>
              <div className="h-1 bg-gradient-to-r from-blue-500 to-purple-600 rounded-b-lg"></div>
            </div>


            <div className="feature flex flex-col rounded-lg shadow-lg bg-gray-800">
              <div className="flex-1 p-6">
                <div className="flex items-center mb-4">
                  <img src="https://cdn.economyplus.solutions/spy.png" alt="Feature 2 Icon" className="w-12 h-12" />
                  <h3 className="text-2xl font-bold ml-4">Alternate Accounts</h3>
                </div>
                <p>Crack down on players evading bans using Fairplay's over 20 configurable anti alt modules.</p>
              </div>
              <div className="h-1 bg-gradient-to-r from-pink-500 to-orange-500 rounded"></div>
            </div>

            <div className="feature flex flex-col rounded-lg shadow-lg bg-gray-800">
              <div className="flex-1 p-6">
                <div className="flex items-center mb-4">
                  <img src="https://cdn.economyplus.solutions/analytics.png" alt="Feature 3 Icon" className="w-12 h-12" />
                  <h3 className="text-2xl font-bold ml-4">Analytics</h3>
                </div>
                <p>Manage and optimize your realm by viewing metrics such as kick rates, average playtime, retention rates and more.</p>
              </div>
              <div className="h-1 bg-gradient-to-r from-green-500 to-blue-500 rounded"></div>
            </div>
          </div>

        </div>
      </div>
    )
}
export default SellingPoints;