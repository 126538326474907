import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { FaHome, FaTachometerAlt, FaDiscord, FaSignOutAlt } from 'react-icons/fa';

const Header = () => {
  const [data, setData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 600);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 600);
    });
    setIsLoading(true);
    fetch('https://panel.economyplus.solutions/getsession', {
      headers: {},
      withCredentials: true,
      credentials: 'include',
    })
      .then((res) => {
        if (res.status === 401) {
          throw new Error('Unauthorized');
        }
        return res.json();
      })
      .then((data) => {
        if (data && data[0]) {
          if (data[0].avatar) {
            setData(`https://cdn.discordapp.com/avatars/${data[0].id}/${data[0].avatar}.png?size=48`);
          } else {
            setData(data[0].username.charAt(0).toUpperCase());
          }
          setIsLoggedIn(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === 'Unauthorized') {
          setIsLoggedIn(false);
        }
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <header className="p-4 w-full bg-gray-900 bg-opacity-80">
        <nav>
          <ul className="flex justify-between items-center">
            <div className="flex items-center">
              <img src="https://pub-e3fa2d3127c84b1aa671e771e3e8e598.r2.dev/favicon.webp" alt="Logo" className="w-12 h-12 mr-6" />
            </div>

            {isMobile ? (
              <></>
            ) : (
              <div className="flex justify-center"></div>
            )}

            <div className="flex">
              <li>
                {isLoggedIn ? (
                  <div className="relative">
                    <button onClick={() => setIsOpen(!isOpen)} className="flex items-center justify-center w-full">
                      {data && data.includes('http') ? (
                        <img src={data} alt="Profile" className="h-10 w-10 rounded-full" />
                      ) : (
                        <div className="h-10 w-10 rounded-full flex items-center justify-center bg-gray-700 text-white">
                          {data}
                        </div>
                      )}
                    </button>
                    {isOpen && (
                      <div className={`absolute right-0 w-48 mt-2 py-2 bg-gray-900 rounded-10px shadow-xl overflow-hidden ${isOpen ? 'animate-slideDown' : ''}`}>
                        <Link href="/dashboard" className="block px-4 py-2 text-white hover:bg-indigo-600 flex items-center">
                          <FaTachometerAlt className="mr-3" />
                          <span>Dashboard</span>
                        </Link>
                        <div className="border-t border-gray-700 my-2"></div>
                        <Link href="https://discord.gg/fairplay" className="block px-4 py-2 text-white hover:bg-indigo-600 flex items-center">
                          <FaDiscord className="mr-3" />
                          <span>Support</span>
                        </Link>
                        <Link href="https://panel.economyplus.solutions/logout" className="block px-4 py-2 text-white hover:bg-indigo-600 flex items-center">
                          <FaSignOutAlt className="mr-3" />
                          <span>Logout</span>
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link href="https://panel.economyplus.solutions/authenticate?redirect=https://dino.economyplus.solutions/dashboard" className="bg-blue-500 text-white px-4 py-2 rounded-md">
                    {isLoading ? 'Loading...' : 'Login'}
                  </Link>
                )}
              </li>
            </div>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Header;
