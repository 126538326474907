function SplashScreen() {
  return (
    <div className="max-w-2xl mx-auto">
      <p className="text-xl mt-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-teal-400 gradient-text-splash">
        Mitigate cheaters, track analytics, and manage your Minecraft Bedrock Realm with ease.
      </p>

      <div className="flex justify-center gap-2 mt-4 flex-col items-center">
        <div className="flex justify-center gap-4">
          <a href="https://discord.com/application-directory/1028476352401248406" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out w-32 h-10">
            Invite Bot
          </a>
          <a href="https://discord.com/invite/XhSEqgFxkW" className="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out w-32 h-10">
            Support
          </a>
        </div>
        <div className="flex justify-center gap-4 mt-4">
          <a href="/pricing" className="bg-sky-500 hover:bg-blue-950 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out w-32 h-10">
            Pricing
          </a>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen;
