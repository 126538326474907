'use client'

import { useEffect, useState } from 'react';
import CountUp from 'react-countup';

const stats = () => {
 
  const [data, setData] = useState({});
  const MAX_RETRIES = 5;

  async function fetchData(retries = MAX_RETRIES) {
    try {
      const response = await fetch('https://api.economyplus.solutions/api/resources');
      const data = await response.json();
      setData(data);
    } catch (error) {
      if (retries > 0) {
        setTimeout(() => fetchData(retries - 1), 500);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1);
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1);
    }
    return num;
  }
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px', marginTop: '15%' }}>
              <h2 className="text-4xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">Stats</h2>
    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
      <div>
        <p className="font-bold text-xl ">Servers</p>
        <CountUp start={0} end={formatNumber(data.guilds)} duration={1.3} suffix="K" decimals={1} decimal="." />
      </div>
      <div>
        <p className="font-bold text-xl">Members</p>
        <CountUp start={0} end={formatNumber(data.membercount)} duration={1.3} suffix="K" decimals={1} decimal="." />
      </div>
      <div>
        <p className="font-bold text-xl">Threats</p>
        <CountUp start={0} end={formatNumber(data.kickrecords)} duration={1.3} suffix="K" decimals={1} decimal="." />
      </div>
    </div>
  </div>
  )
};

export default stats;